import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import { getData, getProfile } from '../../../services/auth';
import { format } from 'date-fns';
import Select from 'react-select';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBSpinner,
  MDBBtn,
  MDBCollapse,
  MDBCol,
  MDBModalFooter,
  MDBTooltip,
} from 'mdbreact';
import { CSVLink } from 'react-csv';
import api from '../../../services/api';

import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import Modal from '../../../components/Modal';

import { Line, Checkbox } from './styles';

export default function Customer(props) {
  const csvLinkRef = useRef(null);
  const csvLinkRefRPO = useRef(null);
  const profile = getData().profile.programs;
  const [searchCollapseID, setSearchCollapseID] = useState(false);
  const [searchCollapseID2, setSearchCollapseID2] = useState(false);

  const [listResult, setListResult] = useState([]);

  const [rows, setRows] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);

  const [dadosModal, setDadosModal] = useState({});
  const [createOrder, setCreateOrder] = useState({});

  const [addProgramList, setAddProgramList] = useState([]);

  const [editDadosModal, setEditDadosModal] = useState({});

  const [viewDate, setViewDate] = useState(' ');
  const [viewOrder, setViewOrder] = useState(' ');
  const [viewBranch, setViewBranch] = useState(' ');
  const [viewProgram, setViewProgram] = useState(' ');

  const [supplierDownload, setSupplierDownload] = useState('');

  const [selectCategory, setSelectCategory] = useState([]);
  const [selectGroup, setSelectGroup] = useState([]);

  const [responseDownload, setResponseDownload] = useState([]);

  const [selectOrders, setSelectOrders] = useState([]);

  const searchCollapse = async () => {
    // alter boolean collapse
    setSearchCollapseID(!searchCollapseID);
    setSearchCollapseID2(false);
    // verify search is true to make request
    if (searchCollapseID) {
      return;
    }
  };
  const searchCollapse2 = async () => {
    // alter boolean collapse
    setSearchCollapseID2(!searchCollapseID2);
    setSearchCollapseID(false);
    // verify search is true to make request
    if (searchCollapseID2) {
      return;
    }
  };

  useEffect(() => {
    // list();
    carregaListas();
    // loadSelectOrders();
  }, []);

  const loadSelectOrders = () => {
    const select = [];
    api.get('/select-orders').then((t) => {
      t.data.forEach((element) => {
        select.push({
          label: element,
          value: element,
        });
      });
    });

    setSelectOrders(select);
  };
  // useEffect(()=>{
  //   if(dadosModal.group == ''){
  //     const { group , ...restante} = dadosModal
  //     setDadosModal(restante)

  //   }

  // },[dadosModal])
  const carregaListas = async () => {
    // load CUSTOMER COLUMN PROGRAM in select
    const responseProgram = await api.get(`/customer`, {
      params: { p: '99999999' },
    });
    const listagemProgram = [];
    profile.map((item) => {
      listagemProgram.push({ value: item.id, label: item.program });
    });

    setAddProgramList(listagemProgram);

    const responseCategory = await api.get(`/product-category?p=99999999`);
    const listagemCategory = [];
    responseCategory.data.rows.map((item) => {
      listagemCategory.push({ value: item.id, label: item.category });
    });
    setSelectCategory(listagemCategory);

    const responseGroup = await api.get(`/product-group?p=99999999`);
    const listagemGroup = [];
    responseGroup.data.rows.map((item) => {
      listagemGroup.push({ value: item.id, label: item.group });
    });
    setSelectGroup(listagemGroup);
  };

  // const simpleSearch = async (npage = page) => {
  //   setLoading(true);
  //   // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`
  //   const response = await api.get(`/orders`, {
  //     params: {
  //       pag: npage,
  //       p: quantity,
  //       order: search,
  //     },
  //   });
  //   setListResult(response.data.rows);

  //   setViewOrder(response.data.rows[0].order);

  //   setViewDate(format(new Date(response.data.rows[0].date), 'dd/MM/yyyy'));
  //   setViewBranch(
  //     response.data.rows[0].TB_customer_listing.TB_customer.branch_name
  //   );
  //   setRows(response.data.count);
  //   setQtdPage(Math.ceil(Number(rows) / Number(quantity)));

  //   setLoading(false);
  // };

  const newOrder = async (npage = page) => {
    if (!createOrder.program) {
      toast.error('The field Program is required');
      return;
    }
    setLoading(true);
    const order = await api.post(`/orders`, {
      // ...createOrder,
      // branch_name: dadosModal.branch_name,
      program: createOrder.program,
      // customer_account_number: dadosModal.customer_account_number,
      // user_id: dadosModal.user_id,
      // customer_group_id: dadosModal.customer_group_id,
      // replan_days:dadosModal.replan_days,
    });

    // const response = await api.get(`/product?pag=${npage}&p=${quantity}&search=${search}`

    const listing = await api.get(`/orders`, {
      params: {
        pag: npage,
        p: quantity,
        order: order.data.order,
      },
    });

    setListResult(listing.data.rows);
    setRows(listing.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));
    setLoading(false);
    setEditDadosModal({});

    setViewOrder(listing.data.rows[0].order);

    setViewDate(format(new Date(listing.data.rows[0].date), 'MM/dd/yyyy'));
    listing.data.rows[0].TB_customer_listing.TB_customer.branch_name &&
      setViewBranch(
        listing.data.rows[0].TB_customer_listing.TB_customer.branch_name
      );
    setViewProgram(
      listing.data.rows[0].TB_customer_listing.TB_customer.program
    );
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    setPage(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    setPage(nextP);
  };

  const handleDownload = async (type) => {
    const formattedDate = (date) => format(new Date(date), 'MM/dd/yyyy');

    try {
      if (type === 'rpo') {
        const response = await api.get(`/order-download-rpo`, {
          params: {
            order: viewOrder,
          },
        });

        const download = [];

        response.data.map((t) => {
          download.push({
            ...t,
            Date: formattedDate(t.Date),
          });
        });

        setResponseDownload(download);

        csvLinkRefRPO.current.link.click();
      } else {
        const response = await api.get(`/order-download`, {
          params: {
            order: viewOrder,
          },
        });
        setSupplierDownload(response.data[0].Supplier);

        const download = [];

        response.data.map((t) => {
          download.push({
            ...t,
            Date: formattedDate(t.Date),
          });
        });

        setResponseDownload(download);
        csvLinkRef.current.link.click();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // useEffect(() => {
  //   list(page);
  // }, [page, quantity]);

  return (
    <>
      <NavBar>
        {/* SEARCH E SEARCH ADVANCED */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi text-left">
              Order Creation
              {getProfile('Super Admin', 'Admin', 'Manager', 'Rep') && (
                <div
                  className={`ml-5 btn btn-sm shadow-5  ${
                    searchCollapseID2
                      ? 'btn-outline-grey grey-text'
                      : 'bg-vmi text-white'
                  }  `}
                  size="sm"
                  onClick={() => searchCollapse2()}
                >
                  <span className="m-0 font-weight-bold ">
                    {/* <MDBIcon className="mr-2" icon="plus" size="1x" />  */}
                    New Order
                  </span>
                </div>
              )}
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          {/* CREATE NEW ORDER */}
          <MDBRow className="mb-3">
            <MDBCollapse
              isOpen={searchCollapseID2}
              className="col-12"
              style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            >
              <MDBRow>
                <MDBCol className="col-12 col-md-4">
                  <span>Program</span>
                  <Select
                    className="w-100"
                    options={addProgramList}
                    isClearable={true}
                    onChange={(e) => {
                      if (e === null) {
                        const { program, ...restante } = createOrder;
                        setCreateOrder(restante);
                        return;
                      }
                      setCreateOrder({ ...createOrder, program: e.label });
                      // se estiver vazio , retiro o elemento
                      if (e.label == '') {
                        const { program, ...restante } = createOrder;
                        setCreateOrder(restante);
                      }
                    }}
                    // value={addProgram}
                  />
                </MDBCol>
                <MDBCol className="col-12 col-md-4">
                  <span>Product Category</span>
                  <Select
                    className="w-100"
                    options={selectCategory}
                    isClearable={true}
                    onChange={(e) => {
                      if (e === null) {
                        const { category, ...restante } = dadosModal;
                        setDadosModal(restante);
                        return;
                      }
                      setDadosModal({ ...dadosModal, category: e.label });
                      // se estiver vazio , retiro o elemento
                      if (e.value == '') {
                        const { category, ...restante } = dadosModal;
                        setDadosModal(restante);
                      }
                    }}
                  />
                </MDBCol>

                <MDBCol className="col-12 col-md-4">
                  <span>Product Group</span>
                  <Select
                    className="w-100"
                    options={selectGroup}
                    isClearable={true}
                    onChange={(e) => {
                      if (e === null) {
                        const { group, ...restante } = dadosModal;
                        setDadosModal(restante);
                        return;
                      }
                      setDadosModal({ ...dadosModal, group: e.label });
                      // se estiver vazio , retiro o elemento
                      if (e.value == '') {
                        const { group, ...restante } = dadosModal;
                        setDadosModal(restante);
                      }
                    }}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol className="col-12 col-md-12 d-flex justify-content-center">
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => {
                      newOrder();
                      // list com a pagina 1 NO BACKEND

                      // seta o valor da pagina NO FRONTEND
                      setPage('1');
                    }}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      Run Order
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCollapse>
          </MDBRow>
        </MDBContainer>

        {/* HEAD FORM TO MD VISUALIZATION */}
        <MDBContainer fluid>
          <MDBRow
            style={{ color: '#3f5c80', borderRadius: '5px' }}
            className="d-flex justify-content-between mb-2 bg-white"
          >
            <MDBCol
              size="2"
              className="h5-responsive text-gray font-weight-bolder"
            >
              Date: {viewDate}
            </MDBCol>
            <MDBCol
              size="3"
              className="h5-responsive text-gray font-weight-bolder text-center"
            >
              Program: {viewProgram}
            </MDBCol>
            <MDBCol
              size="3"
              className="h5-responsive text-gray font-weight-bolder text-center"
            >
              Order: {viewOrder}
            </MDBCol>

            <MDBCol
              size="4"
              className="d-flex justify-content-end align-items-center"
            >
              {listResult.length > 0 ? (
                <>
                  <div
                    onClick={() => handleDownload('rpo')}
                    style={{ color: '#0e4e9e' }}
                    className="mr-5 font-weight-bold cursor-pointer"
                  >
                    RPO
                    <MDBIcon
                      className="ml-2"
                      style={{ color: '#0e4e9e' }}
                      icon="download"
                      size="2x"
                    />
                  </div>

                  <CSVLink
                    data={responseDownload}
                    separator={'\t'}
                    filename={`RPO_${viewProgram}_${viewDate.replace(
                      /\//g,
                      ''
                    )}.txt`}
                    // enclosingCharacter={``}
                    ref={csvLinkRefRPO}
                    style={{ display: 'none' }}
                  />
                  <div
                    onClick={() => handleDownload('down')}
                    style={{ color: '#0e4e9e' }}
                    className="mr-2 font-weight-bold cursor-pointer"
                  >
                    Download
                    <MDBIcon
                      className="ml-2"
                      style={{ color: '#0e4e9e' }}
                      icon="download"
                      size="2x"
                    />
                  </div>

                  <CSVLink
                    data={responseDownload}
                    separator={','}
                    // enclosingCharacter={null}
                    // separator={'\t'}

                    filename={`${supplierDownload}_${viewProgram}_Order_${viewDate.replace(
                      /\//g,
                      ''
                    )}.csv`}
                    ref={csvLinkRef}
                    style={{ display: 'none' }}
                  />
                </>
              ) : (
                // <CSVLink
                //   className="ml-2"
                //   data={responseDownload}
                //   // separator={'\t'}
                //   separator={','}
                //   filename={'order.csv'}
                //   // asyncOnClick={false}
                //   // onClick={async (event, done) => {
                //   //   const response = await api.get(`/customer-listings`, {
                //   //     params: paramsDownload,
                //   //   });
                //   //   responseDownload = response;
                //   //   // console.log(responseDownload);
                //   //   // done();
                //   // }}
                // >
                //   <span
                //     style={{ color: '#0e4e9e' }}
                //     className="mr-2 font-weight-bold"
                //   >
                //     Download
                //   </span>

                //   <MDBIcon
                //     style={{ color: '#0e4e9e' }}
                //     icon="download"
                //     size="2x"
                //   />
                // </CSVLink>
                ''
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <MDBContainer fluid>
          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px solid silver',
            }}
            className="mr-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top"
          >
            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Category:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Manufacturer:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Customer Part No:
              </span>
            </div>

            <div className="col-12 col-md-2 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Vendor Part No:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Unit Price:
              </span>
            </div>
            <div className="col-12 col-md-1 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Order qty:
              </span>
            </div>
          </MDBRow>
        </MDBContainer>

        {/* LIST TABLE */}
        <MDBContainer fluid style={{ borderRadius: '100px' }}>
          {loading === true ? (
            <MDBRow className="mt-5" center>
              <MDBSpinner size="lg" role="status" tag="span" />
            </MDBRow>
          ) : !listResult ? (
            'No Data'
          ) : (
            listResult.map((t) => (
              <div key={t.id}>
                {/* ALTER VALUE SETDADOS FOR DB */}
                <Line key={t.id}>
                  <MDBRow
                    between
                    style={{ borderBottom: '1px solid silver' }}
                    className=" mr-md-0 ml-md-0 p-2"
                  >
                    {/* <div className="col-12 col-md-2 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none">Date:</span>
                        <span>{format(new Date(t.date), 'dd/MM/yyyy')}</span>
                      </div> */}
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none">Category:</span>
                      <span>
                        {t.TB_customer_listing.TB_product.TB_product_group
                          .TB_product_category.category
                          ? t.TB_customer_listing.TB_product.TB_product_group
                              .TB_product_category.category
                          : ''}
                      </span>
                    </div>
                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none">
                        Manufacturer:
                      </span>
                      <span>
                        {t.TB_customer_listing.TB_product
                          .TB_product_manufacturer.manufacturer
                          ? t.TB_customer_listing.TB_product
                              .TB_product_manufacturer.manufacturer
                          : ''}
                      </span>
                    </div>

                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Customer Part No:
                      </span>
                      <span>{t.TB_customer_listing.customer_part_num}</span>
                    </div>

                    <div className="col-12 col-md-2 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Vendor Part No:
                      </span>
                      <span>
                        {t.TB_customer_listing.TB_product.vendor_part_num}
                      </span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Unit Price:
                      </span>
                      <span>{t.TB_customer_listing.unit_price}</span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Order qty:
                      </span>
                      <span>{t.order_qty}</span>
                    </div>
                  </MDBRow>
                </Line>
              </div>
            ))
          )}
        </MDBContainer>

        {/* BUTTON NEXT / BACK OF PAGES */}
        <MDBContainer>
          <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
            <div className="font-weight-bold">
              Rows: <span className="ml-1">{rows}</span>
            </div>

            <div className="font-weight-bold">
              Rows per page:
              <select
                className="muted-text ml-2 mr-5"
                style={{
                  width: '100px',
                  height: '25px',
                  border: '1px solid muted',
                }}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              >
                <option disabled>Per Page</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
              <span className="mr-4">
                {page} of {Math.ceil(rows / quantity)} pages
              </span>
              <MDBIcon
                onClick={prevPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text mr-3"
                icon="chevron-left"
                // size="2x"
              />
              <MDBIcon
                onClick={nextPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text ml-2"
                icon="chevron-right"
                // size="1x"
              />
            </div>
          </Line>
        </MDBContainer>
      </NavBar>
    </>
  );
}
