import React, { useState, useEffect } from 'react';
import NavBar from '../../../components/NavBar';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import api from '../../../services/api';
import { format } from 'date-fns';
import Select from 'react-select';
import { getProfile } from '../../../services/auth';

import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBCollapse,
  MDBCol,
  MDBModalFooter,
} from 'mdbreact';

import { Line } from './styles';
import { CSVLink } from 'react-csv';

export default function ProductGroup(props) {
  const [collapseID, setCollapseID] = useState(false);
  const [group, setGroup] = useState('');
  const [listGroup, setListGroup] = useState([]);
  const [rows, setRows] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);
  const [category, setCategory] = useState([]);
  const [options, setOptions] = useState([]);
  const [categorySelected, setCategorySelected] = useState('');
  const [dadosModal, setDadosModal] = useState({
    // group: '',
    // product_category_id: '',
  });
  const [loadDownload, setLoadDownload] = useState([]);
  // edit
  const [editDadosModal, setEditDadosModal] = useState({});
  // download
  const [typeDownload, setTypeDownload] = useState();
  const [paramsDownload, setParamsDownload] = useState({});
  const [responseDownload, setResponseDownload] = useState([]);
  const [sort, setSort] = useState({ value: 'group', label: 'Group' });
  const [typeSort, setTypeSort] = useState({
    value: 'ASC',
    label: 'Ascending',
  });

  const toggleCollapse = () => {
    setCollapseID(!collapseID);
  };

  useEffect(() => {
    list();
    listCategory();
  }, []);

  useEffect(() => {
    list(1);
  }, [quantity]);

  const listCategory = async () => {
    const response = await api.get(`/product-category?p=1000`);
    await setCategory(response.data.rows);
  };

  useEffect(() => {
    let lista = [];
    category.map((c) => {
      lista.push({ value: c.id, label: c.category });
    });

    setOptions(lista);
  }, [category]);

  // useEffect(()=>{
  //   if(dadosModal.group == ''){
  //     const { group , ...restante} = dadosModal
  //     setDadosModal(restante)

  //   }

  // },[dadosModal])

  const enviaDados = async (e) => {
    try {
      // console.log({group:group,product_category_id:categorySelected})
      await api.post('/product-group', {
        group: group,
        product_category_id: categorySelected,
      });
      setGroup('');
      list();
      toast.success('Added Successfully!');
    } catch (error) {
      toast.error('Error');
    }
  };

  const list = async (npage = page) => {
    window.scrollTo(0, 0);
    setLoading(true);
    const response = await api.get('/product-group', {
      params: {
        search: search,
        p: quantity,
        pag: npage,
        sort: sort.value,
        typeSort: typeSort.value,
      },
    });

    setListGroup(response.data.rows);
    setRows(response.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));

    setLoading(false);

    // const download = { ...response.data.rows,  }

    setLoadDownload(response.data.rows);
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    await setPage(backP);
    list(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    await setPage(nextP);
    list(nextP);
  };

  useEffect(() => {
    list(1);
  }, [sort, typeSort]);

  return (
    <>
      <NavBar>
        {/* BOTAO DE ADICIONAR */}
        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi">
              Product Groups
              {getProfile('Super Admin', 'Admin') && (
                <div
                  className="btn btn-sm shadow-5 bg-vmi ml-5 d-none d-md-inline"
                  size="sm"
                  onClick={() => toggleCollapse()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                  </span>
                </div>
              )}
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
                <MDBCol
                  size="12"
                  className="d-flex d-md-none justify-content-end"
                >
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => toggleCollapse()}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBCollapse isOpen={collapseID}>
            <div
              style={{ borderRadius: '5px' }}
              className="p-3 d-column d-md-flex mr-1 ml-1 mt-1 mr-md-3 ml-md-3 justify-content-center align-items-center bg-white"
            >
              <MDBCol className="col-12 col-md-3">
                <MDBInput
                  label="New Group"
                  icon="clipboard"
                  group
                  size="lg"
                  onChange={(e) => {
                    setGroup(e.target.value);
                  }}
                  value={group}
                />
              </MDBCol>
              <MDBCol className="col-12 col-md-3">
                <span>Product Category</span>
                <Select
                  className="w-100"
                  options={options}
                  onChange={(c) => setCategorySelected(c.value)}
                />
              </MDBCol>
              <MDBCol middle className="col-12 col-md-2">
                <div
                  className="btn btn-sm shadow-5 bg-vmi ml-5 d-none d-md-inline"
                  size="sm"
                  onClick={() => enviaDados()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> ADD
                  </span>
                </div>
              </MDBCol>
            </div>
          </MDBCollapse>
        </MDBContainer>
        {/* BOTAO DE SEARCH E QUANTIDADE */}
        <MDBContainer fluid>
          {/* <Select
                 width='200px'
                // className="col-10  form-control ml-3 flex-1"
                options={options}
                // type="text"
                // placeholder="Search"
                // aria-label="Search"
                // onChange={(e)=>{setSearch(e.target.value)}}

              /> */}
          <MDBRow className="md-form w-100  d-flex flex-column flex-md-row justify-content-center align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <MDBInput
                //  width='200px'
                className="col-10  form-control ml-3 flex-1"
                // options={options}
                type="text"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    list(1);
                  }
                }}
              />
              <MDBIcon
                icon="search"
                size="2x"
                className="text-muted cursor-pointer"
                onClick={() => {
                  setPage('1');
                  list(1);
                }}
              />
            </div>
          </MDBRow>
        </MDBContainer>
        {/* LISTAGEM */}

        <MDBContainer fluid>
          <MDBRow
            style={{ color: '#3f5c80' }}
            className="d-flex justify-content-between mr-4 pl-3"
          >
            <MDBCol
              col="12"
              md="3"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                placeholder="Select Sort"
                options={[
                  { value: 'group', label: 'Group' },
                  {
                    value: 'active',
                    label: 'Activated',
                  },
                ]}
                value={sort}
                onChange={(t) => {
                  setSort(t);
                }}
              />
            </MDBCol>
            <MDBCol
              col="12"
              md="2"
              style={{ backgroundColor: 'rgba(250,250,250,0.6)' }}
              className="p-2"
            >
              <Select
                options={[
                  { value: 'ASC', label: 'Ascending' },
                  { value: 'DESC', label: 'Descending' },
                ]}
                placeholder="Select Type Sort"
                value={typeSort}
                onChange={(t) => {
                  setTypeSort(t);
                }}
              />
            </MDBCol>
            <MDBCol size="2" />
            <MDBCol
              size="4"
              className="d-flex justify-content-end align-items-center"
            >
              <Select
                className="w-50"
                // isClearable={true}

                placeholder="Select Download"
                options={[
                  { value: 0, label: 'Export Current Page' },
                  { value: 1, label: 'Export All' },
                ]}
                onChange={async (c) => {
                  if (c.value == 0) {
                    setLoading(true);
                    setResponseDownload([]);

                    const listing = await api.get(`/product-group`, {
                      params: {
                        pag: page,
                        p: quantity,
                        ...dadosModal,
                        sort: sort.value,
                        typeSort: typeSort.value,
                      },
                    });

                    const download = [];
                    listing.data.rows.forEach((element) => {
                      const {
                        product_category_id,
                        TB_product_category,
                        id,
                        createdAt,
                        updatedAt,
                        ...rest
                      } = element;
                      download.push({
                        ...rest,
                        active: element.active === true ? 'Yes':'No',
                        category: element.TB_product_category.category,
                      });
                    });

                    setResponseDownload(download);
                    setLoading(false);
                  }
                  if (c.value == 1) {
                    setLoading(true);
                    setResponseDownload([]);
                    const response = await api.get(`/product-group`, {
                      params: {
                        pag: 1,
                        p: 999999999,
                      },
                    });
                    const download = [];
                    response.data.rows.forEach((element) => {
                      const {
                        product_category_id,
                        TB_product_category,
                        id,
                        createdAt,
                        updatedAt,
                        ...rest
                      } = element;
                      download.push({
                        ...rest,
                         active: element.active === true ? 'Yes':'No',
                        category: element.TB_product_category.category,
                      });
                    });

                    setResponseDownload(download);
                    setLoading(false);
                  }
                }}
              />

              {responseDownload.length !== 0 ? (
                <CSVLink
                  className="ml-2"
                  data={responseDownload}
                  separator={','}
                  filename={'productGroups.csv'}
                  // asyncOnClick={false}
                  // onClick={async (event, done) => {
                  //   const response = await api.get(`/customer-listings`, {
                  //     params: paramsDownload,
                  //   });
                  //   responseDownload = response;
                  //   // console.log(responseDownload);
                  //   // done();
                  // }}
                >
                  <span
                    style={{ color: '#0e4e9e' }}
                    className="mr-2 font-weight-bold"
                  >
                    Download
                  </span>

                  <MDBIcon
                    style={{ color: '#0e4e9e' }}
                    icon="download"
                    size="2x"
                  />
                </CSVLink>
              ) : (
                ''
              )}
            </MDBCol>
          </MDBRow>

          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px solid silver',
            }}
            className="mr-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top"
          >
            <div className="col-12 col-md-4 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Product Group:
              </span>
            </div>
            <div className="col-12 col-md-4 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">
                Category:
              </span>
            </div>

            <div className="col-12 col-md-3 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">Status:</span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-center"></div>
          </MDBRow>
        </MDBContainer>

        {/* LIST CUSTOMER */}
        <MDBContainer fluid style={{ borderRadius: '100px' }}>
          {loading === true ? (
            <MDBRow className="mt-5" center>
              <MDBSpinner size="lg" role="status" tag="span" />
            </MDBRow>
          ) : listGroup.length > 0 ? (
            listGroup.map((t) => (
              <div key={t.id}>
                {/* ALTER VALUE SETDADOS FOR DB */}
                <Line key={t.id}>
                  <MDBRow
                    between
                    style={{ borderBottom: '1px solid silver' }}
                    className="mr-1 ml-1 mr-md-0 ml-md-0 p-2"
                  >
                    <div className="col-12 col-md-4 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Product Group:
                      </span>
                      <span className="ml-3">{t.group}</span>
                    </div>
                    <div className="col-12 col-md-4 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Category:
                      </span>
                      <span className="ml-3">
                        {t.TB_product_category.category}
                      </span>
                    </div>

                    <div className="col-12 col-md-3 d-flex justify-content-between">
                      <span className="d-sm-inline d-md-none mr-3">
                        Status:
                      </span>
                      <span className="ml-3">
                        {t.active == true ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                    <div className="col-12 col-md-1 d-flex justify-content-center">
                      {/* EDIT MODAL */}
                      <Modal
                        id={t.id}
                        title="Product Group"
                        dados={dadosModal}
                        api={`/product-group/${t.id}`}
                        restart={async () => await list()}
                        disabled={!getProfile('Super Admin', 'Admin')}
                      >
                        <>
                        <MDBRow>
                              <MDBCol className="h5-responsive" col="12" md="6">
                                Creation Date:
                                {t.createdAt
                                  ? format(new Date(t.updatedAt), 'dd/MM/yyyy')
                                  : ''}
                              </MDBCol>
                              <MDBCol className="h5-responsive" col="12" md="6">
                                {t.active === true ? 'Modified Date:': <span className='text-danger'>Discontinued Date:</span>  }
                            
                                {format(new Date(t.updatedAt), ' dd/MM/yyyy')}
                              </MDBCol>
                            </MDBRow>
                          <MDBInput
                            disabled={!getProfile('Super Admin', 'Admin')}
                            label="Product Group"
                            group
                            size="lg"
                            valueDefault={t.group}
                            onChange={(e) => {
                              setDadosModal({
                                ...dadosModal,
                                group: e.target.value,
                              });
                              if (e.target.value == '') {
                                const { group, ...restante } = dadosModal;
                                setDadosModal(restante);
                              }
                            }}
                          />
                          <br />
                          <div className="h5-responsive">Product Category</div>
                          <Select
                            isDisabled={!getProfile('Super Admin', 'Admin')}
                            className="w-100"
                            options={options}
                            onChange={(c) =>
                              setDadosModal({
                                ...dadosModal,
                                product_category_id: c.value,
                              })
                            }
                            defaultValue={{
                              label: t.TB_product_category.category,
                              value: t.TB_product_category.id,
                            }}
                          />
                          <br />
                          <MDBRow>
                            <MDBCol col="12">
                              Status:
                              <Select
                                isDisabled={!getProfile('Super Admin', 'Admin')}
                                className="w-100"
                                options={[
                                  { value: '1', label: 'Active' },
                                  { value: '0', label: 'Inactive' },
                                ]}
                                defaultValue={{
                                  label:
                                    t.active === true ? 'Active' : 'Inactive',
                                  value: t.active === true ? '1' : '0',
                                }}
                                onChange={(e) => {
                                  setDadosModal({
                                    ...dadosModal,
                                    active: e.value,
                                  });

                                  // se estiver vazio , retiro o elemento
                                  // if (e.value == '') {
                                  //   const { active, ...restante } = dadosModal;
                                  //   setDadosModal(restante);
                                  // }
                                }}
                              />
                            </MDBCol>
                          </MDBRow>
                        </>
                      </Modal>
                    </div>
                  </MDBRow>
                </Line>
              </div>
            ))
          ) : (
            <MDBRow center>No Data</MDBRow>
          )}
          <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
            <div className="font-weight-bold">
              Rows: <span className="ml-1">{rows}</span>
            </div>

            <div className="font-weight-bold">
              Rows per page:
              <select
                className="muted-text ml-2 mr-5"
                style={{
                  width: '100px',
                  height: '25px',
                  border: '1px solid muted',
                }}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              >
                <option disabled>Per Page</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="1000">1000</option>
              </select>
              <span className="mr-4">
                {page} of {Math.ceil(rows / quantity)} pages
              </span>
              <MDBIcon
                onClick={prevPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text mr-3"
                icon="chevron-left"
                // size="2x"
              />
              <MDBIcon
                onClick={nextPage}
                style={{ cursor: 'pointer', fontSize: '14px' }}
                className="grey-text ml-2"
                icon="chevron-right"
                // size="1x"
              />
            </div>
          </Line>
        </MDBContainer>
      </NavBar>
    </>
  );
}
