import React, { useState, useEffect } from 'react';
import NavBar from '../../../components/NavBar';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import api from '../../../services/api';
import Select from 'react-select';
import { getData, getProfile } from '../../../services/auth';
import { Link } from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import {
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBInput,
  MDBBtn,
  MDBCollapse,
  MDBCol,
  MDBSpinner,
} from 'mdbreact';

import { format, set } from 'date-fns';

import Loading from '../../../components/Loading';
import { Line } from './styles';

export default function ProductGroup(props) {
  const [collapseID, setCollapseID] = useState(false);
  const [group, setGroup] = useState('');
  const [listGroup, setListGroup] = useState([]);
  const [rows, setRows] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState('1');
  const [quantity, setQuantity] = useState('20');
  const [loading, setLoading] = useState(false);
  const [qtdPage, setQtdPage] = useState(1);
  const [supplier, setSupplier] = useState([]);
  const [options, setOptions] = useState([]);
  const [supplierSelected, setSupplierSelected] = useState('');
  const [dadosModal, setDadosModal] = useState({});
  const [updateInputGroup, setUpdateInputGroup] = useState(false);
  const [loadDownload, setLoadDownload] = useState([]);
  const [paramsDownload, setParamsDownload] = useState({});
  const [responseDownload, setResponseDownload] = useState([]);
  const [sort, setSort] = useState({
    value: 'TB_inventory_supplier',
    label: 'Vendor',
  });
  const [typeSort, setTypeSort] = useState({
    value: 'ASC',
    label: 'Ascending',
  });

  const toggleCollapse = () => {
    setCollapseID(!collapseID);
  };

  useEffect(() => {
    list();
    listSupplier();
  }, []);
  // useEffect(() => {
  //   list(1);
  // }, [quantity]);

  const listSupplier = async () => {
    const response = await api.get(`/inventory-supplier?p=100000000`);
    await setSupplier(response.data.rows);
  };

  useEffect(() => {
    let lista = [];
    supplier.map((c) => {
      lista.push({ value: c.id, label: c.supplier });
    });

    setOptions(lista);
  }, [supplier]);

  useEffect(() => {
    list(1);
  }, [sort, typeSort, quantity]);

  // useEffect(()=>{
  //   if(dadosModal.group == ''){
  //     const { group , ...restante} = dadosModal
  //     setDadosModal(restante)

  //   }

  // },[dadosModal])

  const enviaDados = async () => {
    try {
      // console.log({group:group,product_category_id:categorySelected})
      await api.post('/customer-group', {
        program_group: group,
        supplier_id: supplierSelected,
      });
      setGroup('');
      list();
      toast.success('Added Successfully!');
    } catch (error) {
      toast.error('Error');
    }
  };

  const list = async (npage = page) => {
    setLoading(true);
    const response = await api.get(
      `/customer-group`,
      {
        params: {
          pag: npage,
          p: quantity,
          search,
          sort: sort.value,
          typeSort: typeSort.value,
        },
      }

      // {
      //   params: {
      //     search: search,
      //     p:quantity
      //   }
      // }
    );

    setListGroup(response.data.rows);

    setRows(response.data.count);
    setQtdPage(Math.ceil(Number(rows) / Number(quantity)));

    setLoading(false);

    let newList = [];
    setParamsDownload({
      pag: 1,
      p: 99999999999,
      ...dadosModal,
      sort: sort.value,
      typeSort: typeSort.value,
    });
    response.data.rows.map((row) => {
      const { TB_inventory_supplier, ...rest } = row;
      rest.supplier = TB_inventory_supplier.supplier;
      newList.push(rest);
    });
    setLoadDownload(newList);

    // const { TB_inventory_supplier, ...rest } = response.data.rows;
    // console.log(rest);
    // rest['supplier'] = TB_inventory_supplier.supplier;

    // setLoadDownload(rest);
  };

  const prevPage = async () => {
    if (page == 1) return;
    const backP = Number(page) - 1;
    await setPage(backP);
    list(backP);
  };

  const nextPage = async () => {
    if (Number(page) + 1 > Math.ceil(Number(rows) / Number(quantity))) return;
    const nextP = Number(page) + 1;
    await setPage(nextP);
    list(nextP);
  };

  return (
    <>
      <NavBar>
        {/* {loading === true ? <Loading /> : ''} */}

        {/* BOTAO DE ADICIONAR */}

        <MDBContainer fluid>
          <MDBRow between>
            <MDBCol middle className="h2 text-vmi">
              Program Groups
              {getProfile('Super Admin', 'Admin') && (
                <div
                  className="btn btn-sm shadow-5 bg-vmi ml-5 d-none d-md-inline"
                  size="sm"
                  onClick={() => toggleCollapse()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                  </span>
                </div>
              )}
            </MDBCol>
            <MDBCol middle>
              <MDBRow>
                <MDBCol className="text-right" size="12">
                  <Link
                    to="/app"
                    className="btn btn-sm shadow-5 bg-vmi text-white font-weight-bold"
                    size="sm"
                    onClick={() => toggleCollapse()}
                  >
                    <MDBIcon className="mr-2" fas icon="arrow-left" size="1x" />
                    Back
                  </Link>
                </MDBCol>
                <MDBCol
                  size="12"
                  className="d-flex d-md-none justify-content-end"
                >
                  <div
                    className="btn btn-sm shadow-5 bg-vmi"
                    size="sm"
                    onClick={() => toggleCollapse()}
                  >
                    <span className="m-0 font-weight-bold text-white">
                      <MDBIcon className="mr-2" icon="plus" size="1x" /> New
                    </span>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBCollapse isOpen={collapseID}>
            <div
              style={{
                borderRadius: '5px',
                backgroundColor: 'rgba(255,255,255,0.8)',
              }}
              className="p-3 d-column d-md-flex mr-1 ml-1 mt-1 mr-md-3 ml-md-3 justify-content-center align-items-center"
            >
              <MDBCol className="col-12 col-md-3">
                <MDBInput
                  label="New Program Group"
                  group
                  size="lg"
                  onChange={(e) => {
                    setGroup(e.target.value);
                  }}
                  value={group}
                />
              </MDBCol>
              <MDBCol className="col-12 col-md-3">
                <Select
                  className="w-100"
                  options={options}
                  onChange={(c) => setSupplierSelected(c.value)}
                />
              </MDBCol>
              <MDBCol className="col-12 col-md-2 d-flex justify-content-center">
                <div
                  className="btn btn-sm shadow-5 bg-vmi"
                  size="sm"
                  onClick={() => enviaDados()}
                >
                  <span className="m-0 font-weight-bold text-white">
                    <MDBIcon className="mr-2" icon="plus" size="1x" /> ADD
                  </span>
                </div>
              </MDBCol>
            </div>
          </MDBCollapse>

          {/* BOTAO DE SEARCH E QUANTIDADE */}

          <MDBRow>
            <MDBCol
              middle
              className="d-flex align-items-center justify-content-center"
            >
              <MDBInput
                style={{ width: '300px' }}
                type="text"
                placeholder="Program Group"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    list(1);
                  }
                }}
              />
              <MDBIcon
                icon="search"
                size="2x"
                className="text-muted cursor-pointer"
                onClick={() => {
                  setPage('1');

                  list(1);
                }}
              />
            </MDBCol>

            {/* <MDBBtn
              className="col-4 col-md-1"
              outline
              color="blue-grey"
              rounded
              size="sm"
              type="submit"
              onClick={() => {
                setPage('1');
                list(1);
              }}
            >
              Search
            </MDBBtn> */}
          </MDBRow>
        </MDBContainer>
        {/* LISTAGEM */}
        <MDBContainer fluid>
          <MDBRow style={{ color: '#3f5c80' }}>
            <MDBCol size="12" md="6" className="p-2">
              <MDBRow>
                <MDBCol size="12" md="4" className="my-1">
                  <Select
                    placeholder="Select Sort"
                    options={[
                      { value: 'TB_inventory_supplier', label: 'Vendor' },
                      { value: 'program_group', label: 'Program Group' },
                      // { value: 'active', label: 'Status' },
                    ]}
                    value={sort}
                    onChange={(t) => {
                      setSort(t);
                    }}
                  />
                </MDBCol>
                <MDBCol size="12" md="4" className="my-1">
                  <Select
                    options={[
                      { value: 'ASC', label: 'Ascending' },
                      { value: 'DESC', label: 'Descending' },
                    ]}
                    value={typeSort}
                    placeholder="Select Type Sort"
                    onChange={(t) => {
                      setTypeSort(t);
                      // typeSort = t.value;
                      // list(1);
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCol>

            <MDBCol size="12" md="6" className="p-2 mt-1 ">
              <MDBRow end>
                <MDBCol size="6" md="4">
                  <Select
                    className="w-100"
                    placeholder="Select Download"
                    options={[
                      { value: 0, label: 'Export Current Page' },
                      { value: 1, label: 'Export All' },
                    ]}
                    onChange={async (c) => {
                      let rightDownload = [];
                      let resultValues = [];
                      if (c.value == 0) {
                        setLoading(true);
                        setResponseDownload([]);
                        const listing = await api.get(`/customer-group`, {
                          params: {
                            pag: page,
                            p: quantity,
                            ...dadosModal,
                            // sort,
                            // typeSort,
                          },
                        });
                        resultValues = listing.data.rows;
                      }
                      if (c.value == 1) {
                        setLoading(true);
                        setResponseDownload([]);
                        const listing = await api.get(`/customer-group`, {
                          params: paramsDownload,
                        });
                        resultValues = listing.data.rows;
                      }
                      resultValues.map((t) => {
                        rightDownload.push({
                          program_group: t.program_group,

                          supplier: t.TB_inventory_supplier.supplier,
                        });
                      });

                      setResponseDownload(rightDownload);
                      setLoading(false);
                    }}
                  />
                </MDBCol>

                {responseDownload.length !== 0 ? (
                  <MDBCol size="6" md="3">
                    <CsvDownloader
                      style={{ cursor: 'pointer', width: '200px' }}
                      className="ml-2"
                      datas={responseDownload}
                      wrapColumnChar=""
                      separator={','}
                      filename={'ProgramGroups.csv'}
                      // asyncOnClick={false}
                      // onClick={async (event, done) => {
                      //   const response = await api.get(`/customer-listings`, {
                      //     params: paramsDownload,
                      //   });
                      //   responseDownload = response;
                      //   // console.log(responseDownload);
                      //   // done();
                      // }}
                    >
                      <span
                        style={{ color: '#0e4e9e' }}
                        className="mr-2 font-weight-bold"
                      >
                        Download
                      </span>

                      <MDBIcon
                        style={{ color: '#0e4e9e' }}
                        icon="download"
                        size="2x"
                      />
                    </CsvDownloader>
                  </MDBCol>
                ) : (
                  ''
                )}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <MDBRow
            between
            style={{
              backgroundColor: 'rgba(250, 250, 250, 0.6)',
              borderBottom: '2px  solid silver',
            }}
            className="mr-1 mr-md-0 ml-md-0 d-none d-md-flex rounded-top py-2"
          >
            <MDBCol size="12" md="3" middle className="d-flex">
              <span className="font-weight-bold d-flex align-items-center">
                Vendor:
              </span>
              {/* <Select className="w-100 ml-3" /> */}
            </MDBCol>
            <MDBCol size="12" md="3" middle className="d-flex">
              <span className="font-weight-bold d-flex align-items-center">
                Program Group:
              </span>
              {/* <Select className="w-100 ml-3" /> */}
            </MDBCol>

            <div className="col-12 col-md-3 d-flex justify-content-between">
              <span className="d-sm-inline font-weight-bold mr-3">Status:</span>
            </div>

            <div className="col-12 col-md-1 d-flex justify-content-center"></div>
          </MDBRow>
          {loading === true ? (
            <MDBRow className="mt-5" center>
              <MDBSpinner size="lg" role="status" tag="span" />
            </MDBRow>
          ) : (
            <>
              {listGroup.map((t) => (
                <div key={t.id}>
                  {/* ALTERA OS VALORES DO SETDADOS PARA O DO BANCO */}

                  <Line>
                    <MDBRow
                      between
                      style={{ borderBottom: '1px solid silver' }}
                      className="mr-1 ml-1 mr-md-0 ml-md-0 p-2"
                    >
                      <div className="col-12 col-md-3 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Vendor:
                        </span>
                        <span className="ml-3">
                          {t.TB_inventory_supplier
                            ? t.TB_inventory_supplier.supplier
                            : 'Null'}
                        </span>
                        {/* <span className="ml-3">{t.updatedAt.split('T')[0]}</span> */}
                        {/* {teste[0]} */}
                      </div>

                      <div className="col-12 col-md-3 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Program Group:
                        </span>
                        <span className="ml-3">{t.program_group}</span>
                      </div>
                      <div className="col-12 col-md-3 d-flex justify-content-between">
                        <span className="d-sm-inline d-md-none mr-3">
                          Status:
                        </span>
                        <span className="ml-3">
                          {/* {t.active} */}

                          {t.active === true ? 'Active' : 'Inactive'}
                        </span>
                      </div>
                      <div className="col-12 col-md-1 d-flex justify-content-center">
                        {/* <Link to={`/product-Group/${t.id}`} className="text-danger"> */}
                        {/* <MDBIcon className="mr-1" far icon="edit" size="1x" /> */}
                        {/* <span>Edit</span> */}
                        {/* </Link> */}
                        <Modal
                          id={t.id}
                          title="Program Group"
                          dados={dadosModal}
                          api={`/customer-group/${t.id}`}
                          restart={async () => await list()}
                          disabled={!getProfile('Super Admin', 'Admin')}
                        >
                          <>
                          <MDBRow>
                            <MDBCol className="h5-responsive" col="12" md="6">
                                Creation Date:
                                {t.createdAt
                                  ? format(new Date(t.updatedAt), 'dd/MM/yyyy')
                                  : ''}
                              </MDBCol>
                              <MDBCol className="h5-responsive" col="12" md="6">
                                {t.active === true ? 'Modified Date:': <span className='text-danger'>Discontinued Date:</span>  }
                            
                                {format(new Date(t.updatedAt), ' dd/MM/yyyy')}
                              </MDBCol>
                            </MDBRow>
                            <MDBRow>
                              <MDBCol col="12" md="12">
                                <MDBInput
                                  disabled={!getProfile('Super Admin', 'Admin')}
                                  label="Program Group"
                                  group
                                  size="lg"
                                  valueDefault={t.program_group}
                                  onChange={(e) => {
                                    setDadosModal({
                                      ...dadosModal,
                                      program_group: e.target.value,
                                    });
                                    if (e.target.value == '') {
                                      const { program_group, ...restante } =
                                        dadosModal;
                                      setDadosModal(restante);
                                    }
                                  }}
                                />
                              </MDBCol>
                            </MDBRow>

                            <br />
                            <div className="h5-responsive">Vendor</div>
                            <Select
                              isDisabled={!getProfile('Super Admin', 'Admin')}
                              className="w-100"
                              options={options}
                              onChange={(c) =>
                                setDadosModal({
                                  ...dadosModal,
                                  supplier_id: c.value,
                                })
                              }
                              defaultValue={{
                                label: t.TB_inventory_supplier
                                  ? t.TB_inventory_supplier.supplier
                                  : 'without values',
                                value: t.TB_inventory_supplier
                                  ? t.TB_inventory_supplier.id
                                  : 'without values',
                              }}
                            />
                            <br />
                            <div className="h5-responsive">Status</div>

                            <Select
                              className="w-100"
                              isDisabled={!getProfile('Super Admin', 'Admin')}
                              options={[
                                { value: 1, label: 'Active' },
                                { value: 0, label: 'Inactive' },
                              ]}
                              defaultValue={{
                                label:
                                  t.active === true ? 'Active' : 'Inactive',
                                value: t.active === true ? true : false,
                              }}
                              // onChange={c=>setDadosModal({...dadosModal, replan_days:c.value})}
                              onChange={(c) => {
                                console.log(c);
                                // se estiver vazio , retiro o elemento
                                if (c.value !== '' && c.value !== undefined) {
                                  setDadosModal({
                                    ...dadosModal,
                                    active: c.value,
                                  });
                                }
                              }}
                            />
                            <br />
                            
                          </>
                        </Modal>
                      </div>
                    </MDBRow>
                  </Line>
                </div>
              ))}
              <Line className="py-3 grey-text d-flex justify-content-between align-items-center px-5 rounded-bottom">
                <div className="font-weight-bold">
                  Rows: <span className="ml-1">{rows}</span>
                </div>

                <div className="font-weight-bold">
                  Rows per page:
                  <select
                    className="muted-text ml-2 mr-5"
                    style={{
                      width: '100px',
                      height: '25px',
                      border: '1px solid muted',
                    }}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  >
                    <option disabled>Per Page</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="1000">1000</option>
                  </select>
                  <span className="mr-4">
                    {page} of {Math.ceil(rows / quantity)} pages
                  </span>
                  <MDBIcon
                    onClick={prevPage}
                    style={{ cursor: 'pointer', fontSize: '14px' }}
                    className="grey-text mr-3"
                    icon="chevron-left"
                    // size="2x"
                  />
                  <MDBIcon
                    onClick={nextPage}
                    style={{ cursor: 'pointer', fontSize: '14px' }}
                    className="grey-text ml-2"
                    icon="chevron-right"
                    // size="1x"
                  />
                </div>
              </Line>
            </>
          )}
        </MDBContainer>
      </NavBar>
    </>
  );
}
