import React, { useState } from 'react';
import { MDBRow, MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact';
import { CSVLink } from 'react-csv'; // Para gerar o CSV
import api from '../../services/api';
import { toast } from 'react-toastify';

export function InsertText({
  fileTemplate,
  apiUrl,
  initialInputText = '',
  showRadioButtons = false, // Prop para controlar a exibição dos radio buttons
  onRadioChange = () => {}, // Prop para passar a função do comportamento do radio button
}) {
  const [radio, setRadio] = useState('program'); // Estado do radio, inicializando com 'program' por padrão
  const [inputAddText, setInputAddText] = useState(initialInputText);
  const [erroMinMax, setErroMinMax] = useState([]);
  const [loadText, setLoadText] = useState(false);
  const [toggleModalText, setToggleModalText] = useState(false); // Controle do modal
  const [errors, setErrors] = useState([]); // Para armazenar os erros retornados pela API

  const onType = (type) => {
    setRadio(type); // Atualiza o estado do radio button
    onRadioChange(type); // Chama a função passada por prop, para lidar com o comportamento do radio button
  };

  const enviaText = async () => {
    if (!inputAddText) {
      toast.error('No Data');
      return;
    }
  
    setLoadText(true);
  
    // Divide o texto por linhas
    const rows = inputAddText.split('\n');
  
    // Verifica se há dados suficientes
    if (rows.length < 2) {
      toast.error('No Data or incomplete data');
      setLoadText(false);
      return;
    }
  
    // Separa a primeira linha como os cabeçalhos
    const headers = rows[0].split('\t'); // Cabeçalhos da primeira linha
  
    // Mapeia as linhas subsequentes (dados) para objetos
    const novaLinha = rows.slice(1).map((row) => {
      const values = row.split('\t');
      let obj = {};
      
      // Mapeia cada valor ao seu respectivo cabeçalho
      headers.forEach((header, index) => {
        obj[header] = values[index] || ''; // Atribui um valor vazio se não houver dado
      });
      
      return obj;
    });
  
    let payload = { data: novaLinha }; // Cria o payload como um array de objetos
  
    // Se o radio estiver presente e selecionado, adiciona o tipo ao payload
    if (showRadioButtons) {
      payload.type = radio;
    }
  
    try {
      const response = await api.post(apiUrl, payload); // Envia o payload adequado
      setErroMinMax(response.data); // Processa a resposta da API
      
      if (response.data.errors && response.data.errors.length > 0) {
        // Caso haja erros, salva-os no estado e mostra o toast
        setErrors(response.data.errors);
        toast.error(`${response.data.errors.length} errors found! Download the CSV with errors.`);
      } else {
        // Se não houver erros, limpa os erros e exibe o sucesso
        setErrors([]);
        toast.success('Data sent successfully!');
      }
    } catch (error) {
      toast.error('Error sending data');
    }
  
    setLoadText(false);
  };
  
  return (
    <>
      <div
        className="btn btn-sm shadow-5 bg-vmi"
        size="sm"
        onClick={() => setToggleModalText(true)}
      >
        <span className="m-0 font-weight-bold text-white">Text</span>
      </div>

      <MDBModal
        size="fluid"
        backdrop={true}
        className="modal-notify text-white"
        isOpen={toggleModalText}
        toggle={() => setToggleModalText(!toggleModalText)}
        aria-hidden={!toggleModalText}
      >
        <MDBModalHeader
          className="bg-vmi text-center"
          tag="h5"
          toggle={() => setToggleModalText(!toggleModalText)}
        >
          Insert Text
        </MDBModalHeader>
        <MDBModalBody className="text-center">

          {/* Verifica se os radio buttons devem ser exibidos */}
    
            <MDBRow className="d-flex justify-content-center">
              <MDBCol className="d-flex justify-content-center p-2">
              {showRadioButtons && (
                <>
                <MDBInput
                  onClick={() => onType('program')}
                  checked={radio === 'program'}
                  label="Program"
                  type="radio"
                  id="radio1"
                />
                <MDBInput
                  onClick={() => onType('program_group')}
                  checked={radio === 'program_group'}
                  label="Program Group"
                  type="radio"
                  id="radio2"
                />
                </>
              )}
                <a className="ml-3" href={fileTemplate}>
                  Template xlsx
                </a>
              </MDBCol>
            </MDBRow>
       

          <div className="input-group">
            <textarea
              className="form-control"
              rows="10"
              value={inputAddText}
              onChange={(e) => setInputAddText(e.target.value)}
            />
          </div>

          <MDBRow center>
            <div
              className="btn btn-sm shadow-5 bg-vmi"
              size="sm"
              onClick={enviaText}
            >
              <span className="m-0 font-weight-bold text-white">Send</span>
            </div>

            <div
              className="btn btn-sm shadow-5 bg-danger"
              size="sm"
              onClick={() => setToggleModalText(!toggleModalText)}
            >
              <span className="m-0 font-weight-bold text-white">Cancel</span>
            </div>
          </MDBRow>

          {/* Exibe o botão de download de CSV se houver erros */}
          {errors.length > 0 && (
            <div className="mt-3">
              <CSVLink
                className="btn btn-sm shadow-5 bg-warning text-dark"
                data={errors}
                separator={'\t'}
                filename={'errors.csv'}
              >
                Download Errors
              </CSVLink>
            </div>
          )}

        </MDBModalBody>
      </MDBModal>
    </>
  );
}
